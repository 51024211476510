<template>
  <div class="px-2 py-1 flex flex-row justify-end">
    <span class="font-medium">{{ numeral(item.km).format('0,0') }}</span>
  </div>
</template>

<script>
export default {
  name: "fleet-column-km",
  props: ['column', 'item'],
  methods: { },
}
</script>

<style scoped>

</style>