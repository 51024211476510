<template>
  <div class="px-2 py-1">
    <span class="font-light">{{ item.location }}</span><br/>
    <span class="font-medium">{{ item.destination }}</span>
  </div>
</template>

<script>
export default {
name: "fleet-column-destination",
  props: ['column', 'item'],
  methods: { },
}
</script>

<style scoped>

</style>