<template>
  <div class="px-2 py-1 flex flex-row justify-end">
    <span class="font-medium">{{ moment(item.exp_date).format('DD/MM/YYYY') }}</span>
  </div>
</template>

<script>
export default {
name: "fleet-column-expected",
  props: ['column', 'item'],
  methods: {
  },
}
</script>

<style scoped>

</style>