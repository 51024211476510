<template>
  <div class="px-2 py-1">
    <span class="font-medium">{{ item.make_text }}</span> {{ item.model_text }}<br/>
    <span class="font-light">{{ item.version }}</span>
  </div>
</template>

<script>
export default {
  name: "fleet-column-description",
  props: ['column', 'item'],
  methods: { },
}
</script>

<style scoped>

</style>