<template>
  <div class="px-2 py-1">
    <span class="font-medium">{{ item.vin }}</span>
  </div>
</template>

<script>
export default {
  name: "fleet-column-identification-small",
  props: ['column', 'item'],
  methods: { },
}
</script>

<style scoped>

</style>